export enum LinkType {
    EMAIL= "EMAIL",
    TELEGRAM = "TELEGRAM",
    TWITTER = "TWITTER",
    GITHUB = "GITHUB",
    LINKEDIN = "LINKEDIN",
    LIVE_DEMO = "LIVE_DEMO",
    CV = "CV",
    INSTAGRAM = "INSTAGRAM",
}
